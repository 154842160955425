import { observable } from "mobx";
import { BranchModel } from "./branchModel";
import { DoctorModel } from "./doctorModel";
import { AppointmentTypeModel } from "./appointmentTypeModel";
import { DatetimeModel } from "./datetimeModel";

export class TotalModel{
  @observable branch: BranchModel;
  @observable doctor: DoctorModel;
  @observable datetime: DatetimeModel;
  @observable details: DetailsModel;

  constructor(){
    this.details = new DetailsModel();
  }
}

export class DetailsModel{
  @observable fullname: string;
  @observable phone: string;
  @observable comment: string;
  @observable appointmentType: AppointmentTypeModel;

  constructor(){
    this.fullname = "";
    this.phone = "";
    this.comment = "";
    this.appointmentType = new AppointmentTypeModel();
  }
}
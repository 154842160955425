import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import { StageModel } from "../../models/stageModel";
import classNames from "classnames";
import { TotalModel, DetailsModel } from "../../models/totalModel";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class HeaderComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  constructor(props: IProps) {
    super(props);
  }

  toStage = (number: number) => {
    if (
      this.injected.applicationStore.stage ==5 ||
      this.injected.applicationStore.stage == number ||
      this.injected.applicationStore.stage < number
    )
      return;
    this.injected.applicationStore.total.details = new DetailsModel();
    this.injected.applicationStore.stage = number;
  };

  render() {
    return (
      <div className="header">
        <div className="header__title">Запись на прием к врачу</div>
        {this.injected.applicationStore.stage > 1 && (
          <div className="header__branch">Филиал: <span>{this.injected.applicationStore.total.branch.name}</span></div>
        )}
        <div className="steps">
          {this.injected.applicationStore.stages.map((step: StageModel) => (
            <div

              className={classNames({
                "steps__step": true,
                "steps__step--active": this.injected.applicationStore.stage >= step.stage,
                "is-disable": this.injected.applicationStore.stage==5
              })}
                
              // className={
              //   this.injected.applicationStore.stage >= step.stage
              //     ? "steps__step steps__step--active"
              //     : "steps__step"
              // }
              onClick={() => this.toStage(step.stage)}
              key={step.stage}
            >
              <span className="steps__count">{step.counter}</span>
              <div className="steps__title">{step.name}</div>
            </div>
          ))}
        </div>
      </div>
    );
  }
}

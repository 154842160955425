import { observable, computed, toJS } from "mobx"
import { BranchModel } from "../models/branchModel";
import Networking from "../api/networking";
import { TotalModel } from "../models/totalModel";
import { SpecializationModel } from "../models/specializationModel";
import { DoctorModel } from "../models/doctorModel";
import moment from 'moment'
import { DatetimeModel } from "../models/datetimeModel";
import { AppointmentModel } from "../models/appointmentModel";
import { AppointmentTypeModel } from "../models/appointmentTypeModel";
import { StageModel } from "../models/stageModel";

export class ApplicationStore{
  @observable stage: number;
  @observable loading: boolean;
  @observable branches: Array<BranchModel>;
  @observable doctors: Array<DoctorModel>;
  @observable total: TotalModel;
  @observable specialization: Array<SpecializationModel>;
  @observable appointmentTypes: Array<AppointmentTypeModel>;
  @observable datetimes: Array<DatetimeModel>;
  @observable error: string;
  @observable result: string;
  @observable stages: Array<StageModel>;
  @observable termsLink: string;
  networking: Networking;

  apiUrl: string;

  constructor(API: string, termsLink: string){
    this.apiUrl = API;
    this.loading = false;
    this.stage = 1;
    this.branches = new Array<BranchModel>();
    this.total = new TotalModel;
    this.specialization = new Array<SpecializationModel>();
    this.doctors = new Array<DoctorModel>();
    this.appointmentTypes = new Array<AppointmentTypeModel>();
    this.datetimes = new Array<DatetimeModel>();
    this.stages = new Array<StageModel>();
    this.error = "";
    this.result = "";
    this.networking = new Networking (API);
    this.termsLink = termsLink;
  }

  @computed get nextDisabled(){
    if(this.stage == 1){
      if(this.total.branch.id)
        return false;
    } else if(this.stage == 2){
      if(this.total.doctor.id)
        return false;
    } else if(this.stage == 3){
      if(this.total.datetime)
        return false;
    } else if(this.stage == 4){
      let stage1 = Boolean(this.total.branch.id);
      let stage2 = Boolean(this.total.doctor.id);
      let stage3 = Boolean(this.total.datetime);
      if(this.total.details.fullname != "" &&
        (this.total.details.phone != "" && this.total.details.phone != "+7 (__) ___-__-__" && this.total.details.phone.indexOf("_") == -1) &&
        this.total.details.appointmentType.id &&
        stage1 && stage2 && stage3
      ){
        return false;
      }
    }
    return true;
  }

  makeAppointment = () => {
    let data: AppointmentModel = {
      "LeadName": this.total.details.fullname,
      "LeadPhone": this.total.details.phone,
      "Date": this.total.datetime.date,
      "TimeBegin": this.total.datetime.timeBegin,
      "TimeEnd":  this.total.datetime.timeEnd,
      "AppointmentTypeId": this.total.details.appointmentType.id,
      "DoctorSpecializationId": this.total.doctor.id,
      "OrganizationId": this.total.branch.id,
      "Comment": this.total.details.comment
    };
    if(this.total.datetime.cabinetId != null){
      data.CabinetId = this.total.datetime.cabinetId;
    }
    
    console.log(JSON.stringify(data))
    this.networking.request<String>("POST", "Schedules", data)
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      console.log(resp)
      if (resp.success) {
        if(resp.statusCode == 200){
          this.error = "На данное время запись невозможна. При повторении - записывайтесь в телефонном режиме.";
        }else if(resp.statusCode == 201){
          this.stage = 5;
        }
      } else {
        this.error = "Ошибка записи на приём. При повторении - записывайтесь в телефонном режиме.";
      }
    });
  }

  getBranches = () => {
    this.loading = true;
    this.networking.request<Array<BranchModel>>("GET", "Organizations", {})
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      if (resp.success) {
        this.branches = resp.payload;
        this.stages = new Array<StageModel>();
        if(this.branches.length == 1){
          this.total.branch = this.branches[0];
          this.stage = 2;
        }
        if(this.branches.length > 1){
          this.stages.push({
            "name": "Филиал",
            "counter": this.stages.length+1,
            "stage": 1
          } as StageModel);
        }
        this.stages.push({
          "name": "Врач",
          "counter": this.stages.length+1,
          "stage": 2
        } as StageModel);
        this.stages.push({
          "name": "Дата и время",
          "counter": this.stages.length+1,
          "stage": 3
        } as StageModel);
        this.stages.push({
          "name": "Запись на прием",
          "counter": this.stages.length+1,
          "stage": 4
        } as StageModel);
      } else {
      }
    });
  }

  getSpecialization = () => {
    this.loading = true;
    this.networking.request<Array<SpecializationModel>>("GET", "Specializations/" + this.total.branch.id, {})
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      if (resp.success) {
        this.specialization = resp.payload;
      } else {
      }
    });
  }

  getDoctors = () => {
    this.loading = true;
    this.networking.request<Array<DoctorModel>>("GET", "DoctorSpecializations/" + this.total.branch.id, {})
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      if (resp.success) {
        this.doctors = resp.payload;
      } else {
      }
    });
  }

  getAppointmentTypes = () => {
    this.loading = true;
    return this.networking.request<Array<AppointmentTypeModel>>("GET", "AppointmentTypes", {})
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      console.log(resp)
      if (resp.success) {
        this.appointmentTypes = resp.payload;
      } else {
      }
    });
  }

  getDateTime = (date: Date ) => {
    this.loading = true;
    let data ={
      "FromDate": moment(date).startOf('month').format("YYYY-MM-DD"),
      "TillDate":  moment(date).endOf('month').format("YYYY-MM-DD"),
      "OrganizationId": this.total.branch.id,
      "UserId": this.total.doctor.userId,
      "SpecializationId": this.total.doctor.specializationId
    };
    this.networking.request<Array<DatetimeModel>>("POST", "DoctorsFreeTime", data)
    .then(resp => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      if (resp.success) {
        this.datetimes = resp.payload;
      } else {
      }
    });
  }
}
import React from 'react';
import { HeaderComponent } from '../../components/UI/headerComponent';
import { Provider } from "mobx-react"
import { ApplicationStore } from '../../store/applicationStore';
import { FormComponent } from '../../components/form/formComponent';

interface IProps{
  API: string;
  termsLink: string;
}

export class App extends React.Component<IProps>{

  constructor(props: IProps){
    super(props);
  }

  render(){
    return (
      <Provider applicationStore={new ApplicationStore(this.props.API, this.props.termsLink)}>
        <FormComponent />
      </Provider>
    );  
  }
}

import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import { Button } from "../UI/buttonComponent";
import { BranchModel } from "../../models/branchModel";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class BranchComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  constructor(props: IProps) {
    super(props);

    this.injected.applicationStore.total.branch = new BranchModel();
    this.injected.applicationStore.getBranches();
  }

  onSelect = (branch: BranchModel) => {
    this.injected.applicationStore.total.branch = branch;
    this.injected.applicationStore.stage = 2;
  }

  render() {
    return (
      <div className="list">
        {this.injected.applicationStore.branches.map(branch => (
          <Button
            key={branch.id}
            class="list-item"
            active={this.injected.applicationStore.total.branch == branch}
            onClick={() => this.onSelect(branch)}
          >{branch.name}</Button>
        ))}
      </div>
    );
  }
}

import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import Calendar from 'react-calendar';
import { observable } from "mobx";
import { Button } from "../UI/buttonComponent";
import moment from 'moment'
import { DatetimeModel } from "../../models/datetimeModel";
import { Loader } from "../UI/loaderComponent";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class DatetimeComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  @observable date: Date;
  @observable datetime: Array<DatetimeModel>;

  constructor(props: IProps) {
    super(props);

    this.date = new Date();
    this.datetime = new Array<DatetimeModel>();
    this.getTimes();
  }

  onChange = (date:any) => {
    this.date = date;
    this.injected.applicationStore.total.datetime = null;
    // this.getTimes();
  }

  chooseDate = (item: DatetimeModel) => {
    this.injected.applicationStore.total.datetime = item;
    this.injected.applicationStore.stage = 4;
  }

  getTimes = () => {
    this.injected.applicationStore.getDateTime(this.date);
  }

  findDay = (day: Date)=>{
    for(let date of this.injected.applicationStore.datetimes){
      if(moment(day).isSame(date.date, 'day')){
        return false
      }
    }
    return true;
  }

  update = (date: any) => {
    this.injected.applicationStore.getDateTime(date.activeStartDate);
  }

  render() {
    return (
      <React.Fragment>
        <Calendar
          className="calendar"
          showNeighboringMonth = {false}
          onChange={this.onChange}
          onActiveDateChange={this.update}
          value={this.date}
          minDetail="month"
          minDate={moment().toDate()}
          tileDisabled = {(props: any)=> {
            return this.findDay(props.date);
            // return this.injected.applicationStore.datetimes.filter((date: DatetimeModel) => {
            //   return moment(date.date).isSame(props.date, 'day')
            // }).length == 0
          }}
          tileClassName="calendar__tile"
        />
        {/* {this.injected.applicationStore.datetimes.filter( (date: DatetimeModel) =>  (moment(date.date).isSame(this.date, 'day')) ).length == 0 && (
          <div className="message">На данный день нет свободных номерков</div>
        )} */}
        <div className="columns">
          {this.injected.applicationStore.datetimes.filter( (date: DatetimeModel) =>  (moment(date.date).isSame(this.date, 'day')) ).map((item, index) => (
            <Button
              key={index}
              class="column-item"
              active={this.injected.applicationStore.total.datetime == item}
              onClick={() => this.chooseDate(item)}
            >
              {moment(item.timeBegin, "HH:mm:ss").format("HH:mm")} - {moment(item.timeEnd, "HH:mm:ss").format("HH:mm")}
            </Button>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export class ApiResponse<T extends Object> {
  success: boolean;
  statusCode: number;
  payload: T;
  error: any

  static error<T>(statusCode: number = 0, error: any = null): ApiResponse<T> {
      var response = new ApiResponse<T>();
      response.success = false;
      response.error = error;
      response.statusCode = statusCode;

      return response;
  }

  static success<T>(payload: T, statusCode: number = 200): ApiResponse<T> {
      var response = new ApiResponse<T>();
      response.success = true;
      response.payload = payload;
      response.statusCode = statusCode;

      return response;
  }
}
import React from "react";
import { ApplicationStore } from "../../store/applicationStore";
import { inject, observer } from "mobx-react";
import { DoctorModel } from "../../models/doctorModel";
import { Button } from "../UI/buttonComponent";
import { observable, toJS } from "mobx";
import { SpecializationModel } from "../../models/specializationModel";

interface IProps{

}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class DoctorComponent extends React.Component<IProps>{
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  @observable specialization: SpecializationModel;
  @observable ddShown: boolean;
  @observable search: string;

  constructor(props: IProps){
    super(props)

    this.injected.applicationStore.total.doctor = new DoctorModel();
    this.injected.applicationStore.getSpecialization();
    this.injected.applicationStore.getDoctors();
    this.specialization = new SpecializationModel();
    this.ddShown = false;

    document.addEventListener("click", this.ddHide.bind(this), true)

  }

  componentWillUnmount(){
    document.removeEventListener("click", this.ddHide.bind(this), true)
  }

  onSelect = (doctor: DoctorModel) => {
    this.injected.applicationStore.total.doctor = doctor;
    this.injected.applicationStore.stage = 3;
  }

  setSpecialization = (specialization: SpecializationModel) => {
    this.ddShown = false;
    this.specialization = specialization;
  }

  ddHide(){
    if(this.ddShown)
      this.ddShown = false;
  }

  onSearch = (value: string) => {
    this.search = value.toLowerCase();
  }
  
  render(){

    return(
      <React.Fragment>
        <div className="list-filter">
          <div className="list-filter__dd">
            <div className="dd">
              <div className="dd__value" onClick={() => this.ddShown = !this.ddShown}>
                {this.specialization.name || "Все специальности"}
              </div>
              <div className={this.ddShown ? "dd__list is-shown" : "dd__list"}>
                <div className={!this.specialization.id ? "dd__option is-active" : "dd__option"} onClick={()=> this.setSpecialization(new SpecializationModel())}>
                  Все специальности
                </div>
                {this.injected.applicationStore.specialization.map(option => (
                  <div className={this.specialization == option ? "dd__option is-active" : "dd__option"} key={option.id}  onClick={()=> this.setSpecialization(option)}>
                    {option.name}
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="list-filter__input">
            <div className="form">
              <div className="form__row">
                <input className="form__control" type="text" id="search" placeholder="Иванов Иван Петрович" onInput={(e) => this.onSearch((e.target as HTMLInputElement).value)}/>
                <label className="form__label" htmlFor="search">Поиск по ФИО</label>
              </div>
            </div>
          </div>
        </div>
        <div className="list">
          {this.injected.applicationStore.doctors.filter(doctor => 
          (doctor.specializationId == this.specialization.id ||
          !this.specialization.id) &&
          doctor.userName.toLowerCase().search(this.search) != -1
          ).map(doctor => (
            <Button
              key={doctor.id}
              class="list-item"
              active={this.injected.applicationStore.total.doctor == doctor}
              onClick={() => this.onSelect(doctor)}
            >
              {doctor.userName} — {doctor.specializationName}
            </Button>
          ))}
        </div>
      </React.Fragment>
    )
  }
}
import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";

interface IProps{
  loading: boolean;
}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class Loader extends React.Component<IProps>{

  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  constructor(props: IProps){
    super(props)
  }

  render(){
    return(
      <div className={this.props.loading ? "loading" : "loading is-hidden"}>
        <div className="loading__inner">
          <div className="loading__bar"></div>
          <div className="loading__bar"></div>
          <div className="loading__bar"></div>
          <div className="loading__bar"></div>
        </div>
      </div>
    )
  }
}